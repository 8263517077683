<template>
  <div class="mobile-carousel" ref="mobileCarousel">
    <div class="img-box" :style="{transform: transform}">
      <img
        v-for="(item, index) in carouselImg"
        :key="index"
        :src="item"
        alt=""
      />
    </div>
    <div class="dot-box">
      <span
        v-for="(item, index) in carouselImg"
        :key="index"
        :class="['dot-item', carouselIndex === index ? 'dot-item-actived' : '']"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    carouselImg: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      carouselIndex: 0,
      width: 0,
      timer: null,
      transform: ""
    };
  },
  methods: {
    carousel() {
      this.timer = setInterval(() => {
        if (this.carouselIndex === this.carouselImg.length - 1) {
          this.carouselIndex = 0;
          this.transform = `translate3d(${-this.width*this.carouselIndex}px, 0, 0)`
        } else {
          this.carouselIndex++;
          this.transform = `translate3d(${-this.width*this.carouselIndex}px, 0, 0)`
        }
      }, 5000);
    },
    resize() {
      this.width = this.$refs.mobileCarousel.clientWidth;
    },
  },
  mounted() {
    this.width = this.$refs.mobileCarousel.clientWidth;
    this.carousel();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
.mobile-carousel {
  width: 100%;
  height: 9.267rem;
  position: relative;
  overflow: hidden;
  .img-box {
    height: 100%;
    display: flex;
    transition: all 0.5s ease-in-out;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dot-box {
    position: absolute;
    bottom: 0.7rem;
    left: 43.5%;
    display: flex;
    align-items: center;
    .dot-item {
      display: inline-block;
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background: #fff;
      margin: 0 0.125rem;
    }
    .dot-item-actived {
      width: 0.4rem;
      height: 0.4rem;
      background: none;
      border: 1px solid #fff;
    }
  }
}
</style>