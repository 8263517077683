<template>
  <div class="outer-page">
    <div class="honor-info">
      <div class="title">
        <h6>honor<span>qualification</span></h6>
        <p>荣誉资质</p>
      </div>
      <div class="profile-detail">
        <div class="honor-list">
          <ul>
            <li
              v-for="(item, index) in honorInfo"
              :key="index"
              @click="newsInfo(item)"
            >
              <a>
                <img :src="item.servicePath + item.cover" />
              </a>
            </li>
          </ul>
          <div class="clears"></div>
        </div>
        <p class="more" @click="goThere">
          <span>more</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      honorInfo: [],
    };
  },
  methods: {
    getList() {
      this.$post("websitnews/grouplist", {
        type: 1,
        keyword: "honoraryCertificate",
      }).then((res) => {
        this.honorInfo = res;
      });
    },
    goThere() {
      this.$router.push({
        name: "mhonorQualification",
      });
    },
    newsInfo(item) {
      this.$router.push({
        name: "mhonorQualification",
        params: {
          id: item.id,
          catId: item.catId
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  .honor-info {
    padding: 0.7rem 3% 0.5rem;

    .profile-detail {
      color: #666;

      .honor-list {
        height: 10.8rem;
        overflow: hidden;

        ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          li {
            float: left;
            width: 48%;
            height: 5rem;
            line-height: 5rem;
            text-align: center;
            margin-bottom: 0.4rem;
            background: #f2f2f2;
            a {
              img {
                width: auto;
                max-width: 95%;
                max-height: 95%;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
  .title {
    padding: 0 3%;
    position: relative;
    text-align: center;
    margin-bottom: 0.5rem;

    h6 {
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 0.8rem;
      text-transform: uppercase;
      color: #ff9c00;

      span {
        color: #333;
      }
    }

    p {
      display: inline-block;
      padding: 0 1rem;
      font-size: 0.533rem;
      background: url(../../../../public/imgs/line.png) center no-repeat;
      background-size: 100% auto;
    }
  }

  .more {
    text-align: center;

    span {
      display: inline-block;
      width: 4rem;
      height: 1.4rem;
      text-align: center;
      line-height: 1.4rem;
      background: #ff9c00;
      color: #fff;
      border-radius: 1.4rem;
      text-transform: uppercase;
      font-size: 0.488rem;
      font-weight: bold;
      margin-top: 0.5rem;
    }
  }

  .clears {
    clear: both;
    line-height: 0;
    overflow: hidden;
    font-size: 0;
    height: 0;
  }
}
</style>