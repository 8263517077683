<template>
  <div class="mobile-home-page">
    <!-- <mobile-carousel :carouselImg="carouselImg"></mobile-carousel> -->
    <Carousel
      v-model="carouselIndex"
      loop
      autoplay
      :height="9.267 * 25"
      arrow="never"
      radius-dot
      :autoplay-speed="5000"
    >
      <CarouselItem v-for="(item, index) in carouselImg" :key="index">
        <img :src="item.icon" alt="" style="width: 100%; height: 9.267rem" />
      </CarouselItem>
    </Carousel>
    <div class="section">
      <ul class="section-box">
        <li
          v-for="(item, index) in groupMember"
          :key="index"
          class="section-item"
          @click="goGroupMember(item)"
        >
          <img :src="item.servicePath + item.icon" alt="" />
          <div class="info-box">
            <h6>
              {{ item.name }}<br />
              {{ item.tips.split("/")[0] }}
              <p>+</p>
            </h6>
          </div>
        </li>
      </ul>
    </div>

    <div class="company-profile" v-if="videoData">
      <div class="title">
        <h6>Company <span>profile</span></h6>
        <p>公司简介</p>
      </div>
      <div class="profile-info">
        <div class="profile-video">
          <img src="/imgs/v.png" style="width: 100%;height:8rem;" alt="">
          <!-- <video
            width="100%"
            height="auto"
            loop="-1"
            controls
            ref="video"
            webkit-playsinline
            playsinline
            x5-playsinline
          >
            <source
              :src="videoData.servicePath + videoData.cover.split(',')[0]"
              type="video/mp4"
            />
            <source
              :src="videoData.servicePath + videoData.cover.split(',')[1]"
              type="video/webm"
            />
            <!-- <source
              :src="videoData.servicePath + videoData.cover"
              type="video/mp4"
            /> 
          </video> -->
        </div>
        <div
          class="profile-detail"
          @click="() => $router.push({ name: 'mgroupIntroduction' })"
        >
          <h6>{{ videoData.name }}</h6>
          <span>{{ videoData.brief }}</span>
          <!-- <p>{{videoData.brief}}</p> -->
        </div>
        <p class="more" @click="companyInfo">
          <span>more</span>
        </p>
      </div>
    </div>

    <div class="company-news">
      <div class="title">
        <h6>Company <span>news</span></h6>
        <p>公司新闻</p>
      </div>
      <div class="news-box">
        <Carousel
          loop
          autoplay
          arrow="never"
          dots="none"
          :autoplay-speed="5000"
        >
          <CarouselItem
            class="news-item"
            v-for="(item, index) in news"
            :key="index"
          >
            <div class="new-item-cont" @click="newsInfo(item.id)">
              <img :src="item.servicePath + item.cover" style="width: 100%" />
              <h6>{{ item.brief }}</h6>
              <!-- <p></p> -->
            </div>
            <div class="news-item-time">
              <h6>
                {{ item.createDate.split(" ")[0].split("-")[0] }} /
                <b
                  >{{ item.createDate.split(" ")[0].split("-")[1] }}-{{
                    item.createDate.split(" ")[0].split("-")[2]
                  }}</b
                >
              </h6>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
      <p class="more" @click="goCompanyNews">
        <span>more</span>
      </p>
    </div>

    <div class="group-members">
      <div class="title">
        <h6>group <span>member</span></h6>
        <p>品牌产品</p>
      </div>
      <nav-bar
        :navData="navData"
        @on-change="enter"
        :current="current"
      ></nav-bar>
      <div
        class="group-detail"
        v-for="(item, index) in caseData"
        :key="index"
        :style="current == index ? { display: 'block' } : { display: 'none' }"
        @click="geCaseDetail(item)"
      >
        <img class="gd-img" :src="item.servicePath + item.icon" />
        <div class="gd-info">
          <h6>{{ item.name }}</h6>
          <!-- <h5>Biomedicine</h5> -->
          <br />
          <p>
            {{ item.tips }}
          </p>
        </div>
      </div>
      <div class="gd-more">
        <a href="" class="more" @click="goCase">
          <span>more</span>
        </a>
      </div>
    </div>
    <honor-info></honor-info>
  </div>
</template>

<script>
import mobileCarousel from "@components/mobile/mobileCarousel";
import navBar from "@components/navBar";
import honorInfo from "@components/mobile/mobileHonor";
export default {
  name: "",
  components: {
    mobileCarousel,
    navBar,
    honorInfo,
  },
  data() {
    return {
      carouselImg: [],
      carouselIndex: 0,
      navData: ["生物医药", "生物农业", "检测认证", "有机食品"],
      current: 0,
      groupMember: [],
      videoData: null,
      news: [],
      caseData: [],
    };
  },
  methods: {
    enter(value) {
      this.current = value;
    },
    //集团成员
    getGroupMember() {
      this.$post("websitemenu/menulist", {
        menuName: "集团成员",
        pageNo: 1,
        pageSize: 4,
      }).then((res) => {
        this.groupMember = res;
      });
    },
    //公司简介
    getVideo() {
      this.$post("websitenews/list/companyInfo", {
        pageNo: 1,
        pageSize: 10,
      }).then((res) => {
        this.videoData = res.list[0];
      });
    },
    //公司新闻
    getNews() {
      this.$post(this.$api.NEWS_INFO.LIST, {
        pageNo: 1,
        pageSize: 3,
        isRecommended: "1",
      }).then((res) => {
        this.news = res.list;
      });
    },
    //品牌产品
    getMember() {
      this.$post("websitemenu/menulist", {
        menuName: "品牌产品",
        pageNo: 1,
        pageSize: 4,
      }).then((res) => {
        this.caseData = res;
      });
    },
    goGroupMember(item) {
      this.$router.push({
        name: "m" + item.url.split("-")[0],
      });
    },
    companyInfo() {
      this.$router.push({
        name: "mgroupIntroduction",
      });
    },
    goCompanyNews() {
      this.$router.push({
        name: "mcompanyNews",
      });
    },
    newsInfo(id) {
      this.$router.push({
        name: "mnewsInfo",
        query: {
          id: id,
          key: this.$route.name,
        },
      });
    },
    goCase() {
      this.$router.push({
        name: "mbrendBioAgriculture",
      });
    },
    geCaseDetail(item) {
      this.$router.push({
        name: "m" + item.url.split("-")[0],
      });
    },
    getCarouse() {
      this.$post(this.$api.IMG_MANAGE.LIST, {
        imgType: "5",
        type: "2",
      }).then((res) => {
        this.carouselImg = res.list;
      });
    },
  },
  mounted() {
    this.getGroupMember();
    this.getVideo();
    this.getNews();
    this.getMember();
    this.getCarouse();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>