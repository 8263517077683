<template>
  <div class="nav-pane">
    <div
      :class="['prev', piexl === 0 ? 'prev-disabled' : '']"
      @click="prev"
    ></div>
    <div class="nav-box" ref="navBox">
      <div :style="{ left: `${piexl}rem` }">
        <span
          class="nav-item"
          v-for="(item, index) in navData"
          :key="index"
          @click="enter(index)"
          :class="['nav-item', current == index ? 'nav-item-active' : '']"
          >{{ item }}</span
        >
      </div>
    </div>
    <div
      :class="[
        'next',
        piexl === -(navData.length - 2) * 6 ? 'next-disabled' : '',
      ]"
      @click="text"
    ></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    navData: {
      type: Array,
      default() {
        return [];
      },
    },
    current: {
      type: Number,
      default() {
        return;
      },
    },
  },
  data() {
    return {
      piexl: 0,
    };
  },
  methods: {
    prev() {
      if (this.piexl === 0) return;
      this.piexl += 6;
    },

    text() {
      if (this.piexl <= -(this.navData.length - 2) * 6) return;
      this.piexl -= 6;
    },
    enter(index) {
      this.$emit("on-change", index);
    },
  },
  mounted() {
    // console.log(window.innerWidth)
  },
};
</script>

<style lang="less" scoped>
.nav-pane {
  width: 100%;
  position: relative;
  .nav-box {
    width: 80%;
    height: 1.4rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    > div {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      transition: all 0.5s ease-in-out;
      .nav-item {
        display: inline-block;
        width: 5.4rem;
        height: 1.4rem;
        text-align: center;
        line-height: 1.4rem;
        border-radius: 1.4rem;
        background: #245f5c;
        font-size: 0.533rem;
        color: #fff;
        margin: 0 0.3rem;
      }
      .nav-item-active {
        color: #fff;
        background: #ff9c00;
      }
    }
  }
  .prev {
    position: absolute;
    top: 0.3rem;
    left: 0;
    width: 0.8rem;
    height: 0.8rem;
    background: url(../../../public/imgs/c-left01.png) left center no-repeat;
    background-size: 100% 100%;
  }
  .prev-disabled {
    background: url(../../../public/imgs/c-left11.png) left center no-repeat;
    background-size: 100% 100%;
  }
  .next {
    position: absolute;
    top: 0.3rem;
    right: 0;
    width: 0.8rem;
    height: 0.8rem;
    background: url(../../../public/imgs/c-right01.png) left center no-repeat;
    background-size: 100% 100%;
  }
  .next-disabled {
    background: url(../../../public/imgs/c-right11.png) left center no-repeat;
    background-size: 100% 100%;
  }
}
</style>